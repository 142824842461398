export const qoranList = [ 
    {
        "number": 1,
        "name": "سُورَةُ ٱلْفَاتِحَةِ",
        "englishName": "Al-Faatiha",
        "nameUz": "Fotiha",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/1.mp3",
        "playing": false,
        "numberOfAyahs": 7,
    },
    {
        "number": 2,
        "name": "سُورَةُ البَقَرَةِ",
        "englishName": "Al-Baqara",
        "nameUz": "Baqara",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/2.mp3",
        "playing": false,
        "numberOfAyahs": 286,
    },
    {
        "number": 3,
        "name": "سُورَةُ آلِ عِمۡرَانَ",
        "englishName": "Aal-i-Imraan",
        "nameUz": "Oli Imron",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/3.mp3",
        "playing": false,
        "numberOfAyahs": 200,
    },
    {
        "number": 4,
        "name": "سُورَةُ النِّسَاءِ",
        "englishName": "An-Nisaa",
        "nameUz": "Niso",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/4.mp3",
        "playing": false,
        "numberOfAyahs": 176,
    },
    {
        "number": 5,
        "name": "سُورَةُ المَائـِدَةِ",
        "englishName": "Al-Maaida",
        "nameUz": "Moida",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/5.mp3",
        "playing": false,
        "numberOfAyahs": 120,
    },
    {
        "number": 6,
        "name": "سُورَةُ الأَنۡعَامِ",
        "englishName": "Al-An'aam",
        "nameUz": "An'om",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/6.mp3",
        "playing": false,
        "numberOfAyahs": 165,
    },
    {
        "number": 7,
        "name": "سُورَةُ الأَعۡرَافِ",
        "englishName": "Al-A'raaf",
        "nameUz": "A'rof",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/7.mp3",
        "playing": false,
        "numberOfAyahs": 206,
    },
    {
        "number": 8,
        "name": "سُورَةُ الأَنفَالِ",
        "englishName": "Al-Anfaal",
        "nameUz": "Anfol",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/8.mp3",
        "playing": false,
        "numberOfAyahs": 75,

    },
    {
        "number": 9,
        "name": "سُورَةُ التَّوۡبَةِ",
        "englishName": "At-Tawba",
        "nameUz": "Tavba",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/9.mp3",
        "playing": false,
        "numberOfAyahs": 129,
    },
    {
        "number": 10,
        "name": "سُورَةُ يُونُسَ",
        "englishName": "Yunus",
        "nameUz": "Yunus",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/10.mp3",
        "playing": false,
        "numberOfAyahs": 109,
    },
    {
        "number": 11,
        "name": "سُورَةُ هُودٍ",
        "englishName": "Hud",
        "nameUz": "Hud",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/11.mp3",
        "playing": false,
        "numberOfAyahs": 123,
    },
    {
        "number": 12,
        "name": "سُورَةُ يُوسُفَ",
        "englishName": "Yusuf",
        "nameUz": "Yusuf",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/13.mp3",
        "playing": false,
        "numberOfAyahs": 111,

    },
    {
        "number": 13,
        "name": "سُورَةُ الرَّعۡدِ",
        "englishName": "Ar-Ra'd",
        "nameUz": "Ra'd",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/13.mp3",
        "playing": false,
        "numberOfAyahs": 43,
    },
    {
        "number": 14,
        "name": "سُورَةُ إِبۡرَاهِيمَ",
        "englishName": "Ibrahim",
        "nameUz": "Ibrohim",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/14.mp3",
        "playing": false,
        "numberOfAyahs": 52,
    },
    {
        "number": 15,
        "name": "سُورَةُ الحِجۡرِ",
        "englishName": "Al-Hijr",
        "nameUz": "Hijr",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/15.mp3",
        "playing": false,
        "numberOfAyahs": 99,
    },
    {
        "number": 16,
        "name": "سُورَةُ النَّحۡلِ",
        "englishName": "An-Nahl",
        "nameUz": "Nahl",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/16.mp3",
        "playing": false,
        "numberOfAyahs": 128,
    },
    {
        "number": 17,
        "name": "سُورَةُ الإِسۡرَاءِ",
        "englishName": "Al-Israa",
        "nameUz": "Isro",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/17.mp3",
        "playing": false,
        "numberOfAyahs": 111,
    },
    {
        "number": 18,
        "name": "سُورَةُ الكَهۡفِ",
        "englishName": "Al-Kahf",
        "nameUz": "Qahf",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/18.mp3",
        "playing": false,
        "numberOfAyahs": 110,
    },
    {
        "number": 19,
        "name": "سُورَةُ مَرۡيَمَ",
        "englishName": "Maryam",
        "nameUz": "Maryam",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/19.mp3",
        "playing": false,
        "numberOfAyahs": 98,

    },
    {
        "number": 20,
        "name": "سُورَةُ طه",
        "englishName": "Taa-Haa",
        "nameUz": "Toha",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/20.mp3",
        "playing": false,
        "numberOfAyahs": 135,

    },
    {
        "number": 21,
        "name": "سُورَةُ الأَنبِيَاءِ",
        "englishName": "Al-Anbiyaa",
        "nameUz": "Anbiyo",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/21.mp3",
        "playing": false,
        "numberOfAyahs": 112,

    },
    {
        "number": 22,
        "name": "سُورَةُ الحَجِّ",
        "englishName": "Al-Hajj",
        "nameUz": "Haj",
        "audio": 'https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/22.mp3',
        "playing": false,
        "numberOfAyahs": 78,

    },
    {
        "number": 23,
        "name": "سُورَةُ المُؤۡمِنُونَ",
        "englishName": "Al-Muminoon",
        "nameUz": "Mu'minun",
        "audio": 'https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/23.mp3',
        "playing": false,
        "numberOfAyahs": 118,

    },
    {
        "number": 24,
        "name": "سُورَةُ النُّورِ",
        "englishName": "An-Noor",
        "nameUz": "Nur",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/24.mp3",
        "playing": false,
        "numberOfAyahs": 64,

    },
    {
        "number": 25,
        "name": "سُورَةُ الفُرۡقَانِ",
        "englishName": "Al-Furqaan",
        "nameUz": "Furqon",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/25.mp3",
        "playing": false,
        "numberOfAyahs": 77,

    },
    {
        "number": 26,
        "name": "سُورَةُ الشُّعَرَاءِ",
        "englishName": "Ash-Shu'araa",
        "nameUz": "Shu'aro",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/26.mp3",
        "playing": false,
        "numberOfAyahs": 227,

    },
    {
        "number": 27,
        "name": "سُورَةُ النَّمۡلِ",
        "englishName": "An-Naml",
        "nameUz": "Naml",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/27.mp3",
        "playing": false,
        "numberOfAyahs": 93,

    },
    {
        "number": 28,
        "name": "سُورَةُ القَصَصِ",
        "englishName": "Al-Qasas",
        "nameUz": "Qasas",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/28.mp3",
        "playing": false,
        "numberOfAyahs": 88,

    },
    {
        "number": 29,
        "name": "سُورَةُ العَنكَبُوتِ",
        "englishName": "Al-Ankaboot",
        "nameUz": "Ankabut",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/29.mp3",
        "playing": false,
        "numberOfAyahs": 69,

    },
    {
        "number": 30,
        "name": "سُورَةُ الرُّومِ",
        "englishName": "Ar-Room",
        "nameUz": "Rum",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/30.mp3",
        "playing": false,
        "numberOfAyahs": 60,

    },
    {
        "number": 31,
        "name": "سُورَةُ لُقۡمَانَ",
        "englishName": "Luqman",
        "nameUz": "Luqmon",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/31.mp3",
        "playing": false,
        "numberOfAyahs": 34,

    },
    {
        "number": 32,
        "name": "سُورَةُ السَّجۡدَةِ",
        "englishName": "As-Sajda",
        "nameUz": "Sajda",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/32.mp3",
        "playing": false,
        "numberOfAyahs": 30,

    },
    {
        "number": 33,
        "name": "سُورَةُ الأَحۡزَابِ",
        "englishName": "Al-Ahzaab",
        "nameUz": "Ahzob",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/33.mp3",
        "playing": false,
        "numberOfAyahs": 73,

    },
    {
        "number": 34,
        "name": "سُورَةُ سَبَإٍ",
        "englishName": "Saba",
        "nameUz": "Saba'",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/34.mp3",
        "playing": false,
        "numberOfAyahs": 54,

    },
    {
        "number": 35,
        "name": "سُورَةُ فَاطِرٍ",
        "englishName": "Faatir",
        "nameUz": "Fotir",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/35.mp3",
        "playing": false,
        "numberOfAyahs": 45,

    },
    {
        "number": 36,
        "name": "سُورَةُ يسٓ",
        "englishName": "Yaseen",
        "nameUz": "Yosin",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/36.mp3",
        "playing": false,
        "numberOfAyahs": 83,

    },
    {
        "number": 37,
        "name": "سُورَةُ الصَّافَّاتِ",
        "englishName": "As-Saaffaat",
        "nameUz": "Soffat",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/37.mp3",
        "playing": false,
        "numberOfAyahs": 182,

    },
    {
        "number": 38,
        "name": "سُورَةُ صٓ",
        "englishName": "Saad",
        "nameUz": "Sod",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/38.mp3",
        "playing": false,
        "numberOfAyahs": 88,

    },
    {
        "number": 39,
        "name": "سُورَةُ الزُّمَرِ",
        "englishName": "Az-Zumar",
        "nameUz": "Zumar",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/39.mp3",
        "playing": false,
        "numberOfAyahs": 75,

    },
    {
        "number": 40,
        "name": "سُورَةُ غَافِرٍ",
        "englishName": "Ghafir",
        "nameUz": "G'ofir",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/40.mp3",
        "playing": false,
        "numberOfAyahs": 85,

    },
    {
        "number": 41,
        "name": "سُورَةُ فُصِّلَتۡ",
        "englishName": "Fussilat",
        "nameUz": "Fussilat",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/41.mp3",
        "playing": false,
        "numberOfAyahs": 54,

    },
    {
        "number": 42,
        "name": "سُورَةُ الشُّورَىٰ",
        "englishName": "Ash-Shura",
        "nameUz": "Sho'ro",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/42.mp3",
        "playing": false,
        "numberOfAyahs": 53,

    },
    {
        "number": 43,
        "name": "سُورَةُ الزُّخۡرُفِ",
        "englishName": "Az-Zukhruf",
        "nameUz": "Zuxruf",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/43.mp3",
        "playing": false,
        "numberOfAyahs": 89,

    },
    {
        "number": 44,
        "name": "سُورَةُ الدُّخَانِ",
        "englishName": "Ad-Dukhaan",
        "nameUz": "Duxon",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/44.mp3",
        "playing": false,
        "numberOfAyahs": 59,

    },
    {
        "number": 45,
        "name": "سُورَةُ الجَاثِيَةِ",
        "englishName": "Al-Jaathiya",
        "nameUz": "Josiya",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/45.mp3",
        "playing": false,
        "numberOfAyahs": 37,

    },
    {
        "number": 46,
        "name": "سُورَةُ الأَحۡقَافِ",
        "englishName": "Al-Ahqaf",
        "nameUz": "Ahqof",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/46.mp3",
        "playing": false,
        "numberOfAyahs": 35,

    },
    {
        "number": 47,
        "name": "سُورَةُ مُحَمَّدٍ",
        "englishName": "Muhammad",
        "nameUz": "Muhammad",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/47.mp3",
        "playing": false,
        "numberOfAyahs": 38,
    },
    {
        "number": 48,
        "name": "سُورَةُ الفَتۡحِ",
        "englishName": "Al-Fath",
        "nameUz": "Fath",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/48.mp3",
        "playing": false,
        "numberOfAyahs": 29,
    },
    {
        "number": 49,
        "name": "سُورَةُ الحُجُرَاتِ",
        "englishName": "Al-Hujuraat",
        "nameUz": "Hujurot",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/49.mp3",
        "playing": false,
        "numberOfAyahs": 18,

    },
    {
        "number": 50,
        "name": "سُورَةُ قٓ",
        "englishName": "Qaaf",
        "nameUz": "Qof",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/50.mp3",
        "playing": false,
        "numberOfAyahs": 45,

    },
    {
        "number": 51,
        "name": "سُورَةُ الذَّارِيَاتِ",
        "englishName": "Adh-Dhaariyat",
        "nameUz": "Zoriyot",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/51.mp3",
        "playing": false,
        "numberOfAyahs": 60,

    },
    {
        "number": 52,
        "name": "سُورَةُ الطُّورِ",
        "englishName": "At-Tur",
        "nameUz": "Tur",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/52.mp3",
        "playing": false,
        "numberOfAyahs": 49,

    },
    {
        "number": 53,
        "name": "سُورَةُ النَّجۡمِ",
        "englishName": "An-Najm",
        "nameUz": "Najm",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/53.mp3",
        "playing": false,
        "numberOfAyahs": 62,

    },
    {
        "number": 54,
        "name": "سُورَةُ القَمَرِ",
        "englishName": "Al-Qamar",
        "nameUz": "Qamar",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/54.mp3",
        "playing": false,
        "numberOfAyahs": 55,

    },
    {
        "number": 55,
        "name": "سُورَةُ الرَّحۡمَٰن",
        "englishName": "Ar-Rahmaan",
        "nameUz": "Rahmon",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/55.mp3",
        "playing": false,
        "numberOfAyahs": 78,

    },
    {
        "number": 56,
        "name": "سُورَةُ الوَاقِعَةِ",
        "englishName": "Al-Waaqia",
        "nameUz": "Voqea",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/56.mp3",
        "playing": false,
        "numberOfAyahs": 96,

    },
    {
        "number": 57,
        "name": "سُورَةُ الحَدِيدِ",
        "englishName": "Al-Hadid",
        "nameUz": "Hadid",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/57.mp3",
        "playing": false,
        "numberOfAyahs": 29,

    },
    {
        "number": 58,
        "name": "سُورَةُ المُجَادلَةِ",
        "englishName": "Al-Mujaadila",
        "nameUz": "Mujodala",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/58.mp3",
        "playing": false,
        "numberOfAyahs": 22,

    },
    {
        "number": 59,
        "name": "سُورَةُ الحَشۡرِ",
        "englishName": "Al-Hashr",
        "nameUz": "Hashr",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/59.mp3",
        "playing": false,
        "numberOfAyahs": 24,

    },
    {
        "number": 60,
        "name": "سُورَةُ المُمۡتَحنَةِ",
        "englishName": "Al-Mumtahana",
        "nameUz": "Mumtahana",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/60.mp3",
        "playing": false,
        "numberOfAyahs": 13,

    },
    {
        "number": 61,
        "name": "سُورَةُ الصَّفِّ",
        "englishName": "As-Saff",
        "nameUz": "Sof",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/61.mp3",
        "playing": false,
        "numberOfAyahs": 14,

    },
    {
        "number": 62,
        "name": "سُورَةُ الجُمُعَةِ",
        "englishName": "Al-Jumu'a",
        "nameUz": "Jum'a",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/62.mp3",
        "playing": false,
        "numberOfAyahs": 11,

    },
    {
        "number": 63,
        "name": "سُورَةُ المُنَافِقُونَ",
        "englishName": "Al-Munaafiqoon",
        "nameUz": "Munofiqun",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/63.mp3",
        "playing": false,
        "numberOfAyahs": 11,

    },
    {
        "number": 64,
        "name": "سُورَةُ التَّغَابُنِ",
        "englishName": "At-Taghaabun",
        "nameUz": "Tag'obun",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/64.mp3",
        "playing": false,
        "numberOfAyahs": 18,

    },
    {
        "number": 65,
        "name": "سُورَةُ الطَّلَاقِ",
        "englishName": "At-Talaaq",
        "nameUz": "Taloq",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/65.mp3",
        "playing": false,
        "numberOfAyahs": 12,
 
    },
    {
        "number": 66,
        "name": "سُورَةُ التَّحۡرِيمِ",
        "englishName": "At-Tahrim",
        "nameUz": "Tahrim",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/66.mp3",
        "playing": false,
        "numberOfAyahs": 12,

    },
    {
        "number": 67,
        "name": "سُورَةُ المُلۡكِ",
        "englishName": "Al-Mulk",
        "nameUz": "Mulk",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/67.mp3",
        "playing": false,
        "numberOfAyahs": 30,

    },
    {
        "number": 68,
        "name": "سُورَةُ القَلَمِ",
        "englishName": "Al-Qalam",
        "nameUz": "Qalam",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/68.mp3",
        "playing": false,
        "numberOfAyahs": 52,

    },
    {
        "number": 69,
        "name": "سُورَةُ الحَاقَّةِ",
        "englishName": "Al-Haaqqa",
        "nameUz": "Haaqqa",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/69.mp3",
        "playing": false,
        "numberOfAyahs": 52,

    },
    {
        "number": 70,
        "name": "سُورَةُ المَعَارِجِ",
        "englishName": "Al-Ma'aarij",
        "nameUz": "Maorij",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/70.mp3",
        "playing": false,
        "numberOfAyahs": 44,

    },
    {
        "number": 71,
        "name": "سُورَةُ نُوحٍ",
        "englishName": "Nooh",
        "nameUz": "Nuh",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/71.mp3",
        "playing": false,
        "numberOfAyahs": 28,

    },
    {
        "number": 72,
        "name": "سُورَةُ الجِنِّ",
        "englishName": "Al-Jinn",
        "nameUz": "Jin",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/72.mp3",
        "playing": false,
        "numberOfAyahs": 28,

    },
    {
        "number": 73,
        "name": "سُورَةُ المُزَّمِّلِ",
        "englishName": "Al-Muzzammil",
        "nameUz": "Muzzammil",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/73.mp3",
        "playing": false,
        "numberOfAyahs": 20,

    },
    {
        "number": 74,
        "name": "سُورَةُ المُدَّثِّرِ",
        "englishName": "Al-Muddaththir",
        "nameUz": "Muddassir",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/74.mp3",
        "playing": false,
        "numberOfAyahs": 56,

    },
    {
        "number": 75,
        "name": "سُورَةُ القِيَامَةِ",
        "englishName": "Al-Qiyaama",
        "nameUz": "Qiyomat",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/75.mp3",
        "playing": false,
        "numberOfAyahs": 40,

    },
    {
        "number": 76,
        "name": "سُورَةُ الإِنسَانِ",
        "englishName": "Al-Insaan",
        "nameUz": "Inson",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/76.mp3",
        "playing": false,
        "numberOfAyahs": 31,

    },
    {
        "number": 77,
        "name": "سُورَةُ المُرۡسَلَاتِ",
        "englishName": "Al-Mursalaat",
        "nameUz": "Mursalot",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/77.mp3",
        "playing": false,
        "numberOfAyahs": 50,

    },
    {
        "number": 78,
        "name": "سُورَةُ النَّبَإِ",
        "englishName": "An-Naba",
        "nameUz": "Naba'",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/78.mp3",
        "playing": false,
        "numberOfAyahs": 40,

    },
    {
        "number": 79,
        "name": "سُورَةُ النَّازِعَاتِ",
        "englishName": "An-Naazi'aat",
        "nameUz": "Nazi'at",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/79.mp3",
        "playing": false,
        "numberOfAyahs": 46,

    },
    {
        "number": 80,
        "name": "سُورَةُ عَبَسَ",
        "englishName": "Abasa",
        "nameUz": "Abasa",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/80.mp3",
        "playing": false,
        "numberOfAyahs": 42,

    },
    {
        "number": 81,
        "name": "سُورَةُ التَّكۡوِيرِ",
        "englishName": "At-Takwir",
        "nameUz": "Takvir",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/81.mp3",
        "playing": false,
        "numberOfAyahs": 29,

    },
    {
        "number": 82,
        "name": "سُورَةُ الانفِطَارِ",
        "englishName": "Al-Infitaar",
        "nameUz": "Infitor",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/82.mp3",
        "playing": false,
        "numberOfAyahs": 19,

    },
    {
        "number": 83,
        "name": "سُورَةُ المُطَفِّفِينَ",
        "englishName": "Al-Mutaffifin",
        "nameUz": "Mutoffifin",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/83.mp3",
        "playing": false,
        "numberOfAyahs": 36,

    },
    {
        "number": 84,
        "name": "سُورَةُ الانشِقَاقِ",
        "englishName": "Al-Inshiqaaq",
        "nameUz": "Inshiqoq",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/84.mp3",
        "playing": false,
        "numberOfAyahs": 25,

    },
    {
        "number": 85,
        "name": "سُورَةُ البُرُوجِ",
        "englishName": "Al-Burooj",
        "nameUz": "Buruj",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/85.mp3",
        "playing": false,
        "numberOfAyahs": 22,

    },
    {
        "number": 86,
        "name": "سُورَةُ الطَّارِقِ",
        "englishName": "At-Taariq",
        "nameUz": "Tariq",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/86.mp3",
        "playing": false,
        "numberOfAyahs": 17,

    },
    {
        "number": 87,
        "name": "سُورَةُ الأَعۡلَىٰ",
        "englishName": "Al-A'laa",
        "nameUz": "A'lo",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/87.mp3",
        "playing": false,
        "numberOfAyahs": 19,

    },
    {
        "number": 88,
        "name": "سُورَةُ الغَاشِيَةِ",
        "englishName": "Al-Ghaashiya",
        "nameUz": "G'oshiya",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/88.mp3",
        "playing": false,
        "numberOfAyahs": 26,

    },
    {
        "number": 89,
        "name": "سُورَةُ الفَجۡرِ",
        "englishName": "Al-Fajr",
        "nameUz": "Fajr",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/89.mp3",
        "playing": false,
        "numberOfAyahs": 30,

    },
    {
        "number": 90,
        "name": "سُورَةُ البَلَدِ",
        "englishName": "Al-Balad",
        "nameUz": "Balad",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/90.mp3",
        "playing": false,
        "numberOfAyahs": 20,

    },
    {
        "number": 91,
        "name": "سُورَةُ الشَّمۡسِ",
        "englishName": "Ash-Shams",
        "nameUz": "Shams",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/91.mp3",
        "playing": false,
        "numberOfAyahs": 15,

    },
    {
        "number": 92,
        "name": "سُورَةُ اللَّيۡلِ",
        "englishName": "Al-Lail",
        "nameUz": "Layl",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/92.mp3",
        "playing": false,
        "numberOfAyahs": 21,

    },
    {
        "number": 93,
        "name": "سُورَةُ الضُّحَىٰ",
        "englishName": "Ad-Dhuhaa",
        "nameUz": "Zuho",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/93.mp3",
        "playing": false,
        "numberOfAyahs": 11,

    },
    {
        "number": 94,
        "name": "سُورَةُ الشَّرۡحِ",
        "englishName": "Ash-Sharh",
        "nameUz": "Sharh",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/94.mp3",
        "playing": false,
        "numberOfAyahs": 8,

    },
    {
        "number": 95,
        "name": "سُورَةُ التِّينِ",
        "englishName": "At-Tin",
        "nameUz": "Tiyn",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/95.mp3",
        "playing": false,
        "numberOfAyahs": 8,

    },
    {
        "number": 96,
        "name": "سُورَةُ العَلَقِ",
        "englishName": "Al-Alaq",
        "nameUz": "Alaq",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/96.mp3",
        "playing": false,
        "numberOfAyahs": 19,

    },
    {
        "number": 97,
        "name": "سُورَةُ القَدۡرِ",
        "englishName": "Al-Qadr",
        "nameUz": "Qadr",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/97.mp3",
        "playing": false,
        "numberOfAyahs": 5,

    },
    {
        "number": 98,
        "name": "سُورَةُ البَيِّنَةِ",
        "englishName": "Al-Bayyina",
        "nameUz": "Bayyina",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/98.mp3",
        "playing": false,
        "numberOfAyahs": 8,

    },
    {
        "number": 99,
        "name": "سُورَةُ الزَّلۡزَلَةِ",
        "englishName": "Az-Zalzala",
        "nameUz": "Zalzala",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/99.mp3",
        "playing": false,
        "numberOfAyahs": 8,

    },
    {
        "number": 100,
        "name": "سُورَةُ العَادِيَاتِ",
        "englishName": "Al-Aadiyaat",
        "nameUz": "Odiyat",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/100.mp3",
        "playing": false,
        "numberOfAyahs": 11,

    },
    {
        "number": 101,
        "name": "سُورَةُ القَارِعَةِ",
        "englishName": "Al-Qaari'a",
        "nameUz": "Qori'a",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/101.mp3",
        "playing": false,
        "numberOfAyahs": 11,

    },
    {
        "number": 102,
        "name": "سُورَةُ التَّكَاثُرِ",
        "englishName": "At-Takaathur",
        "nameUz": "Takosur",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/102.mp3",
        "playing": false,
        "numberOfAyahs": 8,

    },
    {
        "number": 103,
        "name": "سُورَةُ العَصۡرِ",
        "englishName": "Al-Asr",
        "nameUz": "Asr",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/103.mp3",
        "playing": false,
        "numberOfAyahs": 3,

    },
    {
        "number": 104,
        "name": "سُورَةُ الهُمَزَةِ",
        "englishName": "Al-Humaza",
        "nameUz": "Humaza",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/104.mp3",
        "playing": false,
        "numberOfAyahs": 9,

    },
    {
        "number": 105,
        "name": "سُورَةُ الفِيلِ",
        "englishName": "Al-Fil",
        "nameUz": "Fil",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/18.mp3",
        "playing": false,
        "numberOfAyahs": 5,

    },
    {
        "number": 106,
        "name": "سُورَةُ قُرَيۡشٍ",
        "englishName": "Quraish",
        "nameUz": "Quraysh",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/106.mp3",
        "playing": false,
        "numberOfAyahs": 4,

    },
    {
        "number": 107,
        "name": "سُورَةُ المَاعُونِ",
        "englishName": "Al-Maa'un",
        "nameUz": "Mo'un",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/107.mp3",
        "playing": false,
        "numberOfAyahs": 7,
    },
    {
        "number": 108,
        "name": "سُورَةُ الكَوۡثَرِ",
        "englishName": "Al-Kawthar",
        "nameUz": "Kavsar",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/108.mp3",
        "playing": false,
        "numberOfAyahs": 3,
    },
    {
        "number": 109,
        "name": "سُورَةُ الكَافِرُونَ",
        "englishName": "Al-Kaafiroon",
        "nameUz": "Kofirun",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/109.mp3",
        "playing": false,
        "numberOfAyahs": 6,
    },
    {
        "number": 110,
        "name": "سُورَةُ النَّصۡرِ",
        "englishName": "An-Nasr",
        "nameUz": "Nasr",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/110.mp3",
        "playing": false,
        "numberOfAyahs": 3,
    },
    {
        "number": 111,
        "name": "سُورَةُ المَسَدِ",
        "englishName": "Al-Masad",
        "nameUz": "Masad",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/111.mp3",
        "playing": false,
        "numberOfAyahs": 5,
    },
    {
        "number": 112,
        "name": "سُورَةُ الإِخۡلَاصِ",
        "englishName": "Al-Ikhlaas",
        "nameUz": "Ixlos",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/112.mp3",
        "playing": false,
        "numberOfAyahs": 4,
    },
    {
        "number": 113,
        "name": "سُورَةُ الفَلَقِ",
        "englishName": "Al-Falaq",
        "nameUz": "Falaq",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/113.mp3",
        "playing": false,
        "numberOfAyahs": 5,
    },
    {
        "number": 114,
        "name": "سُورَةُ النَّاسِ",
        "englishName": "An-Naas",
        "nameUz": "Nos",
        "audio": "https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/114.mp3",
        "playing": false,
        "numberOfAyahs": 6,
    }
]
